import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FrameWrap from '../../components/layout/FrameWrap';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import { DataGrid, DataGridStore, Sort } from '@qbot-chat/qbot-uikit/grid';
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';
import Panel from '../../components/layout/Panel';
import { FormStore } from '../../components/validation';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { confirmDialogStore } from '@qbot-chat/qbot-uikit';
import HistoryContent from '../../components/history/HistoryContent';
import { INTENT_ID_RULE } from '../../const/rules';
import { SCRIPT_TYPE_OPTIONS, USE_YN_OPTIONS } from '../../const/options';
import Styles from './StandardScenarioList.module.scss';
import { FormPrint, headerPrint, headerWidthPrint } from '../../util/dataUtils';
import { SCRIPT_TYPE_ENUM } from '../../components/flow/FlowOptions';
import { TbRefresh } from 'react-icons/tb';

const store = new DataGridStore({
  fetchUrl: `/standardset/scenario/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`intentId`, Sort.SORT_DIRECTION.DESC),
});

const form = new FormStore();

const StandardScenarioList = () => {
  const [initData, setInitData] = useState({});
  const [isIdChecked, setIsIdChecked] = useState(false);
  const [isIdChange, setIsIdChange] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState({});

  const CheckButton = () => (
    <Button variant="outline-primary" onClick={onIdCheck}>
      중복체크
    </Button>
  );

  const standardData = useMemo(() => {
    return [
      [
        {
          label: '인텐트 ID',
          name: 'intentId',
          props: {
            disabled: !!initData?.intentId,
            style: isIdChange ? { borderRadius: '0.375rem 0 0 0.375rem' } : {},
            onChange: onChangeId,
            maxLength: 5,
            button: isIdChange ? <CheckButton /> : null,
          },
          headerProps: {
            sortable: true,
            width: headerWidthPrint(50, 100, 200),
            printFunction: (id) => (
              <Badge pill bg="secondary" className="IdBadge">
                {id}
              </Badge>
            ),
          },
        },
        {
          label: '인텐트 그룹',
          name: 'intentGroup',
          headerProps: {
            width: headerWidthPrint(100, 120, 200),
          },
        },
      ],
      {
        label: '내용',
        name: 'content',
        as: 'textarea',
        headerProps: {
          width: headerWidthPrint(150, 250, 500),
        },
      },
      {
        label: '변수명',
        name: 'vars',
      },
      [
        {
          label: '스크립트 유형',
          name: 'scriptType',
          options: SCRIPT_TYPE_OPTIONS,
          headerProps: {
            width: headerWidthPrint(80, 120, 200),
            printFunction: (scriptType) => (
              <Badge pill bg={SCRIPT_TYPE_ENUM[scriptType]?.colorType}>
                {SCRIPT_TYPE_ENUM[scriptType].label}
              </Badge>
            ),
            className: 'justify-content-center',
          },
        },
        {
          label: '인텐트 설명',
          name: 'intentDesc',
          headerProps: {
            width: headerWidthPrint(80, 120, 200),
          },
        },
      ],
      [
        {
          label: '사용여부',
          name: 'useYn',
          options: USE_YN_OPTIONS,
          headerProps: {
            printFunction: (isUsed) => (
              <Badge bg={isUsed === 'Y' ? 'success' : 'danger'}>
                {isUsed === 'Y' ? '활성화' : '비활성화'}
              </Badge>
            ),
            width: headerWidthPrint(70, 80, 100),
            className: 'justify-content-center',
          },
        },
        {
          label: '작업여부',
          name: 'annoYn',
          options: USE_YN_OPTIONS,
        },
        {
          label: '파싱여부',
          name: 'parsingYn',
          options: USE_YN_OPTIONS,
        },
      ],
      {
        label: '수정일시',
        name: 'updDate',
        hidden: true,
        headerProps: {
          type: 'datetime',
          width: headerWidthPrint(50, 180, 200),
        },
      },
      {
        label: '수정자',
        name: 'updId',
        hidden: true,
        headerProps: {
          width: headerWidthPrint(50, 150, 200),
        },
      },
    ];
  }, [initData, isIdChange]);

  useEffect(() => {
    store.refresh();
    return () => {
      store.searchCondition.clear();
      onReset();
    };
  }, []);

  const onFetchDetail = useCallback((intentId) => {
    axios.post(`/standardset/scenario/detail`, { intentId }).then((res) => {
      setInitData(res.data);
      form.values = res.data;
    });
  }, []);

  const onReset = () => {
    form.clear();
    setInitData({});
    setIsIdChecked(false);
    setIsIdChange(false);
  };

  const onInsert = () => {
    if (!isIdChecked)
      return NotificationManager.warning('인텐트 ID 중복 확인을 해주세요.');
    if (!form.values?.scriptType)
      return NotificationManager.warning('스크립트 타입은 필수 값입니다.');

    confirmDialogStore.openSave(
      '추가 내역',
      <HistoryContent historyData={standardData} data={form.values} />,
      () => {
        axios.post(`/standardset/scenario/insert`, form.values).then((res) => {
          NotificationManager.success('시나리오 표준셋을 추가했습니다.');
          setInitData({ ...form.values, intentId: res.data });
          store.refresh();
        });
      },
      null,
    );
  };

  const onUpdate = () => {
    if (JSON.stringify(initData) === JSON.stringify(form.values))
      return NotificationManager.warning('수정된 내역이 없습니다.');
    if (!form.values?.scriptType)
      return NotificationManager.warning('스크립트 타입은 필수 값입니다.');

    confirmDialogStore.openSave(
      `수정 내역 (${form.values.intentId})`,
      <HistoryContent
        historyData={standardData}
        prevData={initData}
        data={form.values}
      />,
      () => {
        axios.post(`/standardset/scenario/update`, form.values).then((res) => {
          NotificationManager.success('시나리오 표준셋을 수정했습니다.');
          setInitData({ ...form.values, intentId: res.data });
          store.refresh();
        });
      },
      null,
    );
  };

  const onDelete = () => {
    confirmDialogStore.openRemove(
      '삭제 확인',
      '정말 삭제하시겠습니까?',
      () => {
        axios
          .post(`/standardset/scenario/delete`, { intentId: initData.intentId })
          .then(() => {
            NotificationManager.success('시나리오 표준셋을 삭제했습니다.');
            onReset();
            store.refresh();
          });
      },
      null,
    );
  };

  const onIdCheck = () => {
    const inputId = form.valueMap.get('intentId');
    if (!INTENT_ID_RULE.regex.test(inputId))
      return NotificationManager.warning(INTENT_ID_RULE.message);

    if (!inputId) return;

    axios.post(`/standardset/scenario/check/${inputId}`).then((res) => {
      if (res.data) {
        NotificationManager.warning(
          '이미 사용중인 시나리오 표준셋 아이디 입니다.',
        );
      } else {
        NotificationManager.success(
          '사용 가능한 시나리오 표준셋 아이디 입니다.',
        );
        setIsIdChecked(true);
        setIsIdChange(false);
      }
    });
  };

  function onChangeId(e) {
    setIsIdChange(e.target.value !== (initData?.intentId ?? ''));
    setIsIdChecked(false);
  }

  const onSearch = (e, keyword = {}) => {
    e?.preventDefault();
    const { intentId, content } = keyword;
    store.searchCondition.set('intentId', intentId ?? '');
    store.searchCondition.set('content', content ?? '');
    store.fetch();
  };

  const onSearchReset = () => {
    setSearchKeyword({});
    onSearch();
  };

  return (
    <FrameWrap>
      <TitlePanel>
        <FormGroup>
          <Col className="justify-content-between align-items-center d-flex">
            <TitlePanelLabel label="시나리오 표준셋 관리" />
            <div className="d-flex gap-2">
              {!initData?.intentId ? (
                <Button variant="outline-primary" onClick={onInsert}>
                  + 표준셋 추가
                </Button>
              ) : (
                <>
                  <Button variant="outline-danger" onClick={onDelete}>
                    삭제
                  </Button>
                  <Button variant="outline-secondary" onClick={onReset}>
                    취소
                  </Button>
                  <Button variant="outline-primary" onClick={onUpdate}>
                    수정
                  </Button>
                </>
              )}
            </div>
          </Col>
        </FormGroup>
      </TitlePanel>
      <Panel className="flex-default d-flex gap-4">
        <div className={Styles.DataGridWrapper}>
          <Panel style={{ border: '1px solid #E8E8E8', borderRadius: '.5rem' }}>
            <Form
              onSubmit={(e) => onSearch(e, searchKeyword)}
              className={Styles.SearchForm}
            >
              <FormGroup className={Styles.SearchContent}>
                <InputGroup style={{ maxWidth: '220px' }}>
                  <InputGroup.Text>인텐트ID</InputGroup.Text>
                  <Form.Control
                    placeholder="ID로 검색"
                    maxLength={5}
                    value={searchKeyword?.intentId ?? ''}
                    onChange={(e) =>
                      setSearchKeyword((prev) => ({
                        ...prev,
                        intentId: e.target.value,
                      }))
                    }
                  />
                </InputGroup>
                <InputGroup className="flex-1">
                  <InputGroup.Text>내용</InputGroup.Text>
                  <Form.Control
                    placeholder="내용으로 검색"
                    value={searchKeyword?.content ?? ''}
                    onChange={(e) =>
                      setSearchKeyword((prev) => ({
                        ...prev,
                        content: e.target.value,
                      }))
                    }
                  />
                </InputGroup>
                <Button
                  variant="outline-secondary"
                  onClick={onSearchReset}
                  style={{
                    aspectRatio: 1,
                    padding: 0,
                    justifyContent: 'center',
                  }}
                >
                  <TbRefresh />
                </Button>
                <Button variant="outline-secondary" type="submit">
                  검색
                </Button>
              </FormGroup>
            </Form>
          </Panel>
          <DataGrid
            store={store}
            keyColumn="intentId"
            columns={headerPrint(standardData)}
            isHighlightFn={(rowData) => rowData.intentId === initData.intentId}
            highlightClass="active-tr"
            onRowClick={(rowData) => {
              onFetchDetail(rowData.intentId);
            }}
          />
        </div>
        <div className={Styles.FormWrapper}>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormPrint form={form} data={standardData} />
          </Form>
        </div>
      </Panel>
    </FrameWrap>
  );
};

export default StandardScenarioList;
