import React, { useEffect, useState } from 'react';
import Styles from './OperationView.module.scss';
import appStore from '../../store/AppStore';
import { USER_TYPE } from '@qbot-chat/qbot-uikit';
import { goBack } from '../../helper/HistoryHelper';
import { Button, Result, Spin, DatePicker, Typography } from 'antd';
import dayjs from 'dayjs';
import axios from 'axios';
import { ArrowRight2 } from 'iconsax-react';

const OperationView = () => {
  const { type, companyId } = appStore;
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [urlMapping, setUrlMapping] = useState({});
  const [validKeys, setValidKeys] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);

  if (![USER_TYPE.ADMIN, USER_TYPE.SUPER].includes(type) || companyId !== 2)
    return goBack();

  const handleDateChange = (date) => {
    const key = date ? date.format('YYYY-MM') : undefined;
    const report = key ? urlMapping[key] || null : null;

    setSelectedMonth(key);
    setIframeSrc(report);
    setIsError(false);
    setLoading(!!report);
  };

  const fetchData = async () => {
    try {
      const res = await axios.post(`/operation/report/list`, { companyId });
      const urlList = res?.data?.items?.reduce((acc, item) => {
        acc[item.reportMonth] = item.reportUrl;
        return acc;
      }, {});
      setUrlMapping(urlList);
      setValidKeys(Object.keys(urlList));

      const currentMonthKey = dayjs().format('YYYY-MM');
      const getClosestValidMonth = (currentMonth) => {
        const validMonths = Object.keys(urlList)
          .filter((month) => month <= currentMonth)
          .sort((a, b) => b.localeCompare(a));

        return validMonths.length > 0 ? validMonths[0] : null;
      };

      const defaultMonth = urlList[currentMonthKey]
        ? currentMonthKey
        : getClosestValidMonth(currentMonthKey);

      setIframeSrc(defaultMonth ? urlList[defaultMonth] : null);
      setSelectedMonth(defaultMonth);
    } catch (error) {
      console.error('데이터 로드 실패', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const disabledDate = (current) => {
    const key = current.format('YYYY-MM');
    return !validKeys.includes(key);
  };

  return (
    <div className={Styles.OperationWrapper}>
      <div className={Styles.Header}>
        <Typography.Title level={5} className={Styles.Title}>
          <div className={Styles.ArrowIcon}>
            <ArrowRight2 size={'16'} />
          </div>
          운영 리포트
        </Typography.Title>
        <DatePicker
          picker="month"
          onChange={handleDateChange}
          value={selectedMonth ? dayjs(selectedMonth) : null} // defaultValue 대신 value 사용
          disabledDate={disabledDate}
          allowClear
        />
      </div>
      <div className={Styles.IframeWrapper}>
        {loading && <Spin className={Styles.Loading} size="large" />}
        {isError ? (
          <Result
            status="warning"
            className={Styles.ResultWrapper}
            title="운영 리포트 페이지에 에러가 발생했습니다."
            subTitle={
              <>
                불편을 드려 죄송합니다. 잠시 후 다시 시도해 주세요. <br />
                문제가 지속될 경우 퀀텀에이아이(02-6408-0915)를 통해 문의해
                주시기 바랍니다.
              </>
            }
            extra={
              <Button type="primary" onClick={goBack}>
                뒤로가기
              </Button>
            }
          />
        ) : iframeSrc ? (
          <iframe
            className={Styles.Iframe}
            src={iframeSrc}
            title="운영리포트"
            width="100%"
            height="100%"
            onLoad={() => setLoading(false)}
            onError={() => {
              setLoading(false);
              setIsError(true);
            }}
          />
        ) : (
          !loading && (
            <Result
              status="warning"
              className={Styles.ResultWrapper}
              title="운영 리포트가 존재하지 않습니다."
              subTitle={
                <>
                  선택된 월의 리포트가 없습니다. 다른 월을 선택해 주세요.
                  <br />
                  문제가 지속될 경우 퀀텀에이아이(02-6408-0915)를 통해 문의해
                  주시기 바랍니다.
                </>
              }
            />
          )
        )}
      </div>
    </div>
  );
};

export default OperationView;
