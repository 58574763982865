import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Badge, Button, Form, InputGroup } from 'react-bootstrap';
import Styles from './NodeFlow.module.scss';
import { SCRIPT_TYPE_ENUM } from './FlowOptions';

const FlowSideList = ({ listApi }) => {
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    fetchIntent();
  }, [listApi]);

  const fetchIntent = () => {
    if (!listApi) return;
    axios
      .post(listApi, { keyword })
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => console.error(err));
  };

  const onSearch = (e) => {
    e.preventDefault();
    fetchIntent();
    setKeyword('');
  };

  const onDragStart = (e, data) => {
    e.dataTransfer.setData('application/reactflow', JSON.stringify(data));
    e.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className={Styles.List}>
      <Form className={Styles.Search} onSubmit={onSearch}>
        <InputGroup>
          <Form.Control
            type="text"
            placeholder="검색어 입력"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <Button variant="outline-secondary" type="submit">
            검색
          </Button>
        </InputGroup>
      </Form>
      <ul>
        {list.map((v) => (
          <li key={v.intentId} onDragStart={(e) => onDragStart(e, v)} draggable>
            <div>
              <Badge pill bg={SCRIPT_TYPE_ENUM[v.scriptType]?.colorType}>
                {SCRIPT_TYPE_ENUM[v.scriptType]?.label}
              </Badge>
              <Badge className={Styles.Badge} pill text="secondary">
                {v.intentId}
              </Badge>
            </div>
            <span className={Styles.IntentText}>{v.intentGroup}</span>
            <p>{v.content}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FlowSideList;
